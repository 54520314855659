<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card light>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5" style="word-break: break-word;">
                    Módulos da Empresa
                </span>
                <v-spacer/>
                <v-btn @click="$emit('close')" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <v-row v-for="(module, index) in modules" :key="index">
                    <v-col>
                        <v-switch
                            @change="onToggleModule(module.id, $event)"
                            :label="module.nome"
                            :input-value="isActive(module.id)"
                            inset
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ModulosDialog',

    props: ['visible', 'value'],

    data: () => ({
        modules: [],
    }),

    mounted() {
        this.fillModules();
    },

    methods: {
        isActive(id) {
            const isActive = !!this.value.modulos?.find(m => {
                return m.modulo_id == id
            })

            return isActive
        },

        onToggleModule(modulo, value) {
            const data = {
                modulo,
                value,
                empresa: this.value.id
            };
            this.$root.$emit('loading', true);
            this.$http.post('empresa/set-modulo', data).then(resp => {
                if (resp.data?.data?.type == 'warning') {
                    alert(resp.data.data.msg)
                    return;
                }
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.$root.$emit('loading', false)));
        },

        fillModules() {
            this.$http.get('modulos').then(resp => {
                this.modules = resp.data.data;
            });
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
