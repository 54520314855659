<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card light>
            <v-card-title class="justify-center">
                <v-spacer></v-spacer>
                <span class="text-h5" style="word-break: break-word;">
                    Inicio Período de Teste
                </span>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text align="center" justify="center">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Data inicio do teste grátis"
                            type="date"
                            v-model="form.trial_started_at"
                            outlined
                            dense
                            hide-details
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="confirmar">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'InicioTesteGratis',

    props: ['visible', 'value'],

    data: () => ({
        form: {},
    }),

    mounted() {
        this.form.trial_started_at = this.moment().format('YYYY-MM-DD');
    },

    methods: {
        confirmar() {
            if (!this.form.trial_started_at) {
                alert('Preencha todos os campos');
                return;
            }

            const data = {
                ...this.form,
                ...{empresa: this.value},
            }
            this.$root.$emit('loading', true);
            this.$http.post('empresa/set-inicio-teste-gratis', data).then(resp => {
                if (resp.data?.data?.type == 'warning') {
                    alert(resp.data.data.msg)
                    return;
                }

                this.$emit('confirm', resp.data);
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação', 'warning');
            }).finally(() => (this.$root.$emit('loading', false)));
        }
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
