<template>
    <v-dialog v-model="visible" persistent max-width="500px">
        <v-card light>
            <v-card-title class="headline justify-center">
                <v-icon color="green" style="font-size: 40px;">mdi-checkbox-marked-circle-outline</v-icon>
                Assinatura emitida com sucesso!
            </v-card-title>
            <v-card-text class=text-center>
                
                <div v-if="qrCode" class="pa-1">
                    <v-img class="mx-auto" height="250" width="250" :src="qrCode"/>
                </div>
                <div v-if="boleto" class="pa-1">
                    <a :href="boleto" target="_blank">Abrir boleto</a>
                </div>
                <div v-if="link" class="pa-1">
                    <a :href="link" target="_blank">Compartilhar</a>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" style="color: white;" @click="$emit('confirm')" block>Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AssinaturaConcluida',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        value: {
            type: Object,
            default: () => {}
        }
    },

    data: () => ({
    }),

    computed: {
        qrCode() {
            return this.value?.data?.payment.data.pix.qrcode_image;
        },

        boleto() {
            return this.value?.data?.payment.data.pdf.charge;
        },

        link() {
            return this.value?.data?.payment.data.link;
        },
    },

    methods: {
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
