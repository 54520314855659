var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseContainer',[_c('span',{attrs:{"slot":"principal"},slot:"principal"},[_c('v-content',{staticClass:"pr-2 pl-2",staticStyle:{"background-color":"#555"}},[_c('v-toolbar',{staticClass:"ma-2",staticStyle:{"border-radius":"30px"}},[_c('v-btn',{attrs:{"icon":"","link":"","to":"/"}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('v-toolbar-title',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_vm._v("Empresas")]),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","outlined":"","single-line":"","hide-details":"","label":"Pesquise..","append-icon":"mdi-magnify"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.consultar.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-checkbox',{attrs:{"label":"Listar somente inadimplentes","hide-details":""},model:{value:(_vm.somenteInadimplentes),callback:function ($$v) {_vm.somenteInadimplentes=$$v},expression:"somenteInadimplentes"}}),_c('v-spacer'),_c('PeriodoFilter',{on:{"update":_vm.consultar},model:{value:(_vm.periodo),callback:function ($$v) {_vm.periodo=$$v},expression:"periodo"}})],1),_c('v-card',{staticClass:"ma-1",attrs:{"elevation":"2"}},[_c('v-card-text',[_c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.dados,"mobileBreakpoint":0,"loading":_vm.loading,"height":_vm.$vuetify.breakpoint.height - 230,"disable-pagination":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.database",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("emp_" + (item.database)))+" ")]}},{key:"item.whatsapp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.abrirWhatsapp(item.whatsapp)}}},[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-whatsapp")]),_vm._v("  "),_c('u',[_vm._v(_vm._s(item.whatsapp))])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datetime(item.created_at))+" ")]}},{key:"item.dominio",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.abrirCardapio(item.dominio)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")]),_vm._v("  "),_c('u',[_vm._v(_vm._s(item.dominio))])],1)]}},{key:"item.assinatura_id",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getSubscriptionColor(item),"small":""}},[_vm._v("mdi-circle")]),_vm._v("  "),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editar(item)}}},[_c('u',[_vm._v(_vm._s(item.assinatura_id))])])]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.ativo ? 'green' : 'red',"text-color":"white"}},[_vm._v(" "+_vm._s(item.ativo ? 'Ativo' : 'Inativo')+" ")])]}},{key:"item.assinatura_emissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datetime(item.assinatura_emissao))+" ")]}},{key:"item.data_ultimo_pedido",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red-color': _vm.isDateOlderThan5Days(item) }},[_vm._v(" "+_vm._s(_vm.datetime(item.data_ultimo_pedido))+" ")])]}},{key:"item.trial_started_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.datetime(item.trial_started_at, 'DD/MM/YYYY'))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.isDiretor)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openModulesDialog(item)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-package-variant-plus")]),_vm._v("  Módulos ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.testeGratisDialog(item.id)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-flask")]),_vm._v("  Iniciar teste ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.bloquearDesbloquear(item.id)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v(_vm._s(item.ativo ? 'mdi-lock' : 'mdi-lock-open'))]),_vm._v("  "+_vm._s(item.ativo ? 'Bloquear' : 'Desbloquear')+" ")],1)],1),_c('v-list-item',{on:{"click":function($event){item.assinatura_emissao
                                            ? _vm.cancelarAssinatura(item.id)
                                            : _vm.emitirAssinaturaDialog(item.id)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-cash")]),_vm._v("  "+_vm._s(item.assinatura_emissao ? 'Cancelamento' : 'Emitir assinatura')+" ")],1)],1),(item.assinatura_emissao)?_c('v-list-item',{on:{"click":function($event){return _vm.alterarAssinatura(item.id)}}},[_c('v-list-item-title',[_c('v-icon',[_vm._v("mdi-pencil")]),_vm._v("  Alterar assinatura ")],1)],1):_vm._e()],1)],1):_vm._e()]}}])}),_c('paginate',{on:{"input":_vm.consultar},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),(_vm.visible1)?_c('EmissaoAssinatura',{attrs:{"edit":_vm.edit},on:{"close":function($event){_vm.visible1 = false},"confirm":_vm.emitirAssinatura},model:{value:(_vm.empresaId),callback:function ($$v) {_vm.empresaId=$$v},expression:"empresaId"}}):_vm._e(),_c('AssinaturaConcluida',{attrs:{"visible":_vm.visible2},on:{"confirm":function($event){_vm.visible2 = false}},model:{value:(_vm.cobranca),callback:function ($$v) {_vm.cobranca=$$v},expression:"cobranca"}}),_c('InicioTesteGratis',{attrs:{"visible":_vm.visible3},on:{"close":function($event){_vm.visible3 = false},"confirm":_vm.confirmInicioTesteGratis},model:{value:(_vm.empresaId),callback:function ($$v) {_vm.empresaId=$$v},expression:"empresaId"}}),_c('ModulosDialog',{attrs:{"visible":_vm.visible4},on:{"close":function($event){_vm.visible4 = false}},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }